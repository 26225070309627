.App {
  text-align: center;
  background-color: #121212;
  color: white;
  min-height: 100vh;
}

.App-header {
  background-color: #1e1e1e;
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 24px;
}

.buy-now {
  background-color: #61dafb;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: black;
  /* Ensure the text is black */
}

.buy-now:hover {
  background-color: #21a1f1;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 100px;
  padding: 20px;
  gap: 20px;
  width: 80%;
  max-width: 1200px;
  margin: 100px auto 0;
}

.meme-content {
  flex: 1;
  max-width: 40%;
}

.meme-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-section {
  flex: 1;
  max-width: 40%;
  text-align: left;
  margin-left: 20px;
}

.about-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.about-section p {
  margin: 5px 0;
}

.marquee {
  margin-top: 20px;
  padding: 10px 0;
  background-color: #1e1e1e;
  color: #61dafb;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee span {
  display: inline-block;
  padding-right: 50px;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(150%);
  }

  100% {
    transform: translateX(-150%);
  }
}

.new-section {
  width: 80%;
  max-width: 1200px;
  margin: 40px auto;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.new-section-title {
  text-align: center;
  font-size: 24px;
}

.tokenomics-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 40px 0;
}

.tokenomics-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.tokenomics-list {
  list-style-type: none;
  padding-left: 0;
  font-size: 20px;
  text-align: left;
}

.tokenomics-list li {
  margin: 10px 0;
}

.tokenomics-section .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.help-section-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.help-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 40px auto;
  text-align: left;
  color: white;
  width: 80%;
  max-width: 1200px;
}

.help-image {
  flex: 1;
  max-width: 40%;
  margin-right: 40px;
  /* Added margin to create more space between image and text */
}

.help-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.help-content {
  flex: 1;
  max-width: 40%;
  text-align: left;
}

.help-content p {
  margin: 10px 0;
}

.help-button {
  background-color: #61dafb;
  color: black;
  /* Ensure the text is black */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  text-decoration: none;
  display: inline-block;
}

.help-button:hover {
  background-color: #21a1f1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
  }

  .social-icons {
    margin-top: 10px;
  }

  .content {
    flex-direction: column;
    margin-top: 80px;
  }

  .about-section {
    margin-left: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .meme-content,
  .about-section {
    max-width: 100%;
  }

  .help-section {
    flex-direction: column;
  }

  .help-image {
    margin-right: 0;
    margin-bottom: 20px;
    /* Added margin to create more space between image and text */
    max-width: 100%;
  }

  .help-content {
    max-width: 100%;
  }

  .tokenomics-list {
    font-size: 16px;
  }

  .help-button {
    font-size: 14px;
  }
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu {
  display: none;
  flex-direction: column;
  background-color: #1e1e1e;
  position: absolute;
  top: 50px;
  /* Adjust as needed */
  right: 0;
  width: 100%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  align-items: center;
  /* Add this line to center items horizontally */
}

.mobile-menu a {
  margin: 10px 0;
  color: white;
  font-size: 18px;
  text-decoration: none;
}

.mobile-menu button {
  display: flex;
  width: 30%;
  margin-right: 5px;
  justify-content: center;
  /* Add this line to center the button content */
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .social-icons {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .mobile-menu {
    display: flex;
  }

  .mobile-menu a {
    font-size: 30px;
  }

  .mobile-menu button {
    color: #121212;
  }

  .content {
    flex-direction: column;
    margin-top: 80px;
  }

  .about-section {
    margin-left: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .meme-content,
  .about-section {
    max-width: 100%;
  }

  .help-section {
    flex-direction: column;
  }

  .help-image {
    margin-right: 0;
    margin-bottom: 20px;
    /* Added margin to create more space between image and text */
    max-width: 100%;
  }

  .help-content {
    max-width: 100%;
  }
}


@media (max-width: 500px) {

  .tokenomics-list {
    font-size: 13px;
  }

}


#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media(min-width:1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {

  margin-top: 30px;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}


.supporting-ross-title {
  font-size: 24px;
  text-align: center;
  margin: 40px 0 20px 0;
}

.supporting-ross-image {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.supporting-ross-image img {
  max-width: 80%;
  height: auto;
}


.help-team-message {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  max-width: 80%;
  font-size: 16px;
}